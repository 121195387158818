@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Italianno&display=swap');

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #333;
}

.react-images__view-image {
    max-height: 100vh !important;
}

.header {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url(https://jemaso.s3-ap-southeast-2.amazonaws.com/cover.jpg);
    background-position: 54% 20%;
    background-size: cover;
}

.header > div:nth-child(1) {
    flex: 2.5;
    background: rgba(255, 0, 255, 0.5);
}

.header > div:nth-child(2) {
    flex: 1.5;
    background: rgba(0, 255, 0, 0.5);
}

.header > div:nth-child(3) {
    flex: 1;
    background: rgba(0, 0, 255, 0.5);
}

.header > div:nth-child(4) {
    flex: 1;
    background: rgba(255, 0, 0, 0.5);
}

.header > div:nth-child(5) {
    flex: 1;
    background: rgba(255, 0, 0, 0.5);
}

.header > div {
    background: none !important;
}

.debug-vcenter {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
}

h1 {
    /*font-family: 'Noto Sans JP', sans-serif;*/
    font-family: 'Italianno', sans-serif;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    padding: 0;
    margin: 0;
    font-size: 140px;
    font-weight: normal;
    line-height: 100px;
    user-select: none;
}

.header p {
    font-size: 24px;
    font-weight: normal;
    cursor: pointer;
    padding: 10px;
    margin: 0 0 20px 0;
    color: rgba(255, 255, 255, 0.9);
    font-family: 'Noto Sans JP', Verdana, sans-serif;
    filter: drop-shadow(2px 4px 6px black);
    user-select: none;
}

.react-photo-gallery--gallery {
    margin: 2px;
}

.react-photo-gallery--gallery > div > img {
    object-fit: cover;
    object-position: 50% 40%;
}

.footer {
    max-width: 500px;
    padding: 10px;
    color: #EEE;
    text-align: center;
    margin: 0 auto;
}

a {
    text-decoration: underline;
    color: #EEE;
}

a:hover, a:visited {
    color: #EEE;
}

a:hover {

}

.fullscreen-button {
    position: fixed;
    right: 10px;
    bottom: 10px;
    width: 25px;
    height: 25px;
    background-color: rgba(204, 85, 0, 0.9);
    cursor: pointer;
}

.fullscreen-button:hover {
    filter: drop-shadow(1px 2px 4px black);
}

.mobile {
    display: none;
}

@media only screen and (max-width: 650px) {
    .mobile {
        display: initial;
    }

    .desktop {
        display: none !important;
    }

    .header {
        background-position: 50% 20%;
    }

    .header > div:nth-child(1) {
        flex: 1.5;
    }

    .header > div:nth-child(2) {
        flex: 3.5;
    }

    .header h1 {
        font-size: 100px;
        display: block;
        line-height: 55px;
    }

    .header p {
        font-size: 18px;
    }

    .react-photo-gallery--gallery {
        margin: 1px;
    }
}

